import ReactDOM from 'react-dom';
import {Route} from 'react-router';
import React,{lazy, Suspense} from 'react';
import {Utils} from 'core-web/services/Utils.class';
import * as serviceWorker from 'core-web/serviceWorker';
import {SkeletonHeader} from 'core-web/components/skeleton/SkeletonHeader';
import {PayOrderAMPM} from 'core-web/components/cashier/PayOrderAMPM';
const merchant = Utils.getMerchantByUrl();

const MAC = lazy(() => import('core-web/components/MAC/MAC').then(({MAC}) => ({default: MAC})));
const App = lazy(() => import('core-web/components/app/App').then(({ App }) => ({ default: App })));
const Home = lazy(() => import('core-web/components/home/Home').then(({ Home }) => ({ default: Home })));
const NotFound = lazy(() => import('core-web/components/NotFound').then(({ NotFound }) => ({ default: NotFound })));
const Status = lazy(() => import('core-web/components/cashier/Status').then(({ Status }) => ({ default: Status })));
const PayOrder = lazy(() => import('core-web/components/cashier/PayOrder').then(({ PayOrder }) => ({ default: PayOrder })));
const Shipment = lazy(() => import('core-web/components/shipment/Shipment').then(({ Shipment }) => ({ default: Shipment })));
const Customer = lazy(() => import('core-web/components/customer/Customer').then(({ Customer }) => ({ default: Customer })));
const Cashier = lazy(() => import('core-web/components/cashier/stock/Cashier').then(({ Cashier }) => ({ default: Cashier })));
const Vacations = lazy(() => import('core-web/components/CIISA/Vacations').then(({ Vacations }) => ({ default: Vacations })));
const StatusPSE = lazy(() => import('core-web/components/cashier/StatusPSE').then(({ StatusPSE }) => ({ default: StatusPSE })));
const MainPage = lazy(() => import('core-web/components/cashier/stock/pumaFront/MainPage').then(({ MainPage }) => ({ default: MainPage })));
const MaxStatus = lazy(() => import('core-web/components/cashier/stock/MaxFront/MaxStatus').then(({ MaxStatus }) => ({ default: MaxStatus })));
const SmartService = lazy(() => import('core-web/components/smartService/SmartService').then(({ SmartService }) => ({ default: SmartService })));
const MaxCashier = lazy(() => import('core-web/components/cashier/stock/MaxFront/MaxCashier').then(({ MaxCashier }) => ({ default: MaxCashier })));
const AMPMStatus = lazy(() => import('core-web/components/cashier/stock/AMPMFront/AMPMStatus').then(({ AMPMStatus }) => ({ default: AMPMStatus })));
const HioposStatus = lazy(() => import('core-web/components/cashier/stock/hioposFront/HioposStatus').then(({ HioposStatus }) => ({ default: HioposStatus })));
const StatusCardinal = lazy(() => import('core-web/components/cashier/StatusCardinal').then(({ StatusCardinal }) => ({ default: StatusCardinal })));
const Verification2FA = lazy(() => import('core-web/components/CIISA/Verification2FA').then(({ Verification2FA }) => ({ default: Verification2FA })));
const DetermineVacations = lazy(() => import('core-web/components/CIISA/DetermineVacations').then(({ DetermineVacations }) => ({ default: DetermineVacations })));
const PDFTransactionDownloader = lazy(() => import('core-web/components/PDFTransactionDownloader/PDFTransactionDownloader').then(({PDFTransactionDownloader}) => ({default: PDFTransactionDownloader})));
const AFZCashier = lazy(()=> import('core-web/components/cashier/stock/AFZCashier').then(({ AFZCashier }) => ({ default: AFZCashier })));
const AcMarriottLeads = lazy(()=> import('core-web/components/leads/acMarriott/Lead').then(({ Lead }) => ({ default: Lead })));
ReactDOM.render(
    <Suspense fallback={<SkeletonHeader/>}>
        <App>
            <Route exact path={'/'} render={() => <Home/>}/>
            <Route exact path={'/MAC'} render={() => <MAC/>}/>
            <Route path={'/customer'} render={() => <Customer/>}/>
            <Route exact path={'/0'} render={() => merchant === 'pumacolombia' ? <MainPage/> : merchant === 'belcacr' ? <MaxCashier/> : <Cashier/>}/>
            <Route exact path={'/:orderId'} render={() => merchant !== 'ampm' ? <PayOrder/> : <PayOrderAMPM/> }/>
            <Route exact path={'/shipment/:orderId'} render={() => <Shipment/>}/>
            <Route exact path={'/employee/2FA/:deviceId'} render={() => <Verification2FA/>}/>
            <Route exact path={'/smartService/:targetPoint'} render={() => <SmartService/>}/>
            <Route exact path={'/employee/vacations/:employeeId'} render={() => <Vacations/>}/>
            <Route exact path={'/transaction/payupse/status/:orderId'} render={() => <StatusPSE/>}/>
            <Route exact path={'/transaction/:processor/status/:orderId'} render={() => merchant === 'belcacr' ? <MaxStatus /> : merchant === 'ampm' ? <AMPMStatus/> : merchant === 'hiopos' ? <HioposStatus /> : <Status/>}/>
            <Route exact path={'/transaction/cardinal/status/:orderId'} render={() => <StatusCardinal/>}/>
            <Route exact path={'/0/:category'} render={() => merchant === 'pumacolombia' ? <MainPage/> : merchant === 'belcacr' ? <MaxCashier /> : <Cashier/>}/>
            <Route exact path={'/employee/vacations/notification/:employeeId/:status'} render={() => <DetermineVacations/>}/>
            <Route exact path={'/0/:category/:subcategory'} render={() => merchant === 'pumacolombia' ? <MainPage/>: merchant === 'belcacr' ? <MaxCashier />  : <Cashier/>}/>
            <Route exact path={'/0/:category/:subcategory/:subcategory2'} render={() => <MainPage/>}/>
            <Route exact path={'/getTransactionPDF/:orderId'} render={() => <PDFTransactionDownloader/>}/>
            <Route exact path={'/leads/list'} render={() => <AcMarriottLeads/>}/>
            <Route exact path={'/1/afz'} render={() => <AFZCashier/>}/>
            <Route path="*" render={() => <NotFound/>}/>

        </App>
    </Suspense>,
    document.getElementById('root')
);

serviceWorker.unregister();
